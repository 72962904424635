import { combineEpics } from 'redux-observable'

import { enquiriesEffect } from './enquiriesEffect'
import { eventsEffect } from './eventsEffect'
import { homepageSearchEffect } from './homepageSearchEffect'
import { leaseProfileEffect } from './leaseProfileEffect'
import { locoSpaceEffect } from './locoSpaceEffect'
import { rangesEffect } from './rangesEffect'
import { rewardsPageEffect } from './rewardsPageEffect'
import { searchFiltersEffect } from './searchFiltersEffect'
import { searchPageEffect } from './searchPageLoadEffect'
import { searchParametersEffect } from './searchParametersEffect'
import { userPreferencesEffect } from './userPreferencesEffect'

export const rootEpic = combineEpics(
  enquiriesEffect,
  eventsEffect,
  homepageSearchEffect,
  locoSpaceEffect,
  rangesEffect,
  rewardsPageEffect,
  searchFiltersEffect,
  searchPageEffect,
  searchParametersEffect,
  leaseProfileEffect,
  userPreferencesEffect,
)
