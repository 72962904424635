import { useEffect, useState } from "react"
import { isProd, storageAvailable } from "@/lib/utilities/system"
import { constants } from "@/lib/constants"

export const useAdminMode = () => {
  const [isActive, setIsActive] = useState(false)

  useEffect(() => {
    if (storageAvailable('localStorage')) {
      const isAdminModeActive = localStorage.getItem(constants.localStorageKey.adminMode)

      if (isAdminModeActive && !isProd()) {
        setIsActive(true)
      } else {
        setIsActive(false)
      }
    }
  }, [])

  return isActive
}