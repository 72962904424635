import { combineEpics, ofType } from 'redux-observable'
import { concat, from, of } from 'rxjs'
import { catchError, map, switchMap } from 'rxjs/operators'

import { addError, removeError } from '../reducers/error'
import { clearLeaseProfileState } from '../reducers/leaseProfile'
import { addLoader, removeLoader } from '../reducers/loader'
import {
  hydrateSearchParameters,
  INITIAL_STATE,
  landedOnCarSearchPage,
  landedOnCarSearchPageWithPath,
  landedOnCarSearchPageWithReference,
  landedOnVanSearchPage,
  landedOnVanSearchPageWithPath,
  landedOnVanSearchPageWithReference,
  storeAvailableFilters,
  storeLeaseType,
  storePagination,
  storeSavedSearchReference,
  storeSearchParameters,
  storeSearchResults,
  storeSearchTotals,
  storeVehicleRanges,
  storeVehicleTypes,
  toggleVat,
  storeIsSearchSaved,
} from '../reducers/search'
import { storeQueryString as storeQueryStringInSystem } from '../reducers/system'
import { storeQueryString } from '../reducers/user'
import { selectSearchParameters } from '../selectors/search'
import {
  constants,
  FILTER_LEASE_TYPES_BUSINESS,
  FILTER_LEASE_TYPES_PERSONAL,
  FILTER_PAGE,
  FILTER_VEHICLE_TYPES_CARS,
  FILTER_VEHICLE_TYPES_VANS,
} from '@/lib/constants'
import { ERRORS } from '@/lib/errors'
import { LOADERS } from '@/lib/loaders'
import {
  getSearchWithPathService,
  getSearchWithReferenceService,
  submitSearchService,
} from '@/lib/services/searchService'

export const storeQueryStringEffect = action$ =>
  action$.pipe(
    ofType(storeQueryString),
    map(action => action.payload),
    map(queryString => storeQueryStringInSystem(queryString)),
  )

export const searchPageLoadEffect = (action$, state$) => {
  const LOADER = LOADERS.fetchingSearchResults
  const ERROR = ERRORS.fetchingSearchResults

  return action$.pipe(
    ofType(landedOnCarSearchPage),
    map(action => action.payload),
    switchMap(url => {
      const queryString = url.split('?')[1]
      const isFirstRender = !!queryString
      const existingSearchParams = selectSearchParameters(state$.value)
      const isSearchForVans = existingSearchParams.vehicleTypes === FILTER_VEHICLE_TYPES_VANS

      let searchParameters =
        isFirstRender || isSearchForVans ? INITIAL_STATE.searchParameters : existingSearchParams

      const params = {
        ...searchParameters,
        vehicleTypes: [FILTER_VEHICLE_TYPES_CARS],
        page: 1,
      }

      return concat(
        of(addLoader(LOADER)),
        of(removeError(ERROR)),
        of(storeIsSearchSaved(false)),
        of(hydrateSearchParameters(searchParameters)),
        from(submitSearchService(params)).pipe(
          switchMap(response =>
            concat(
              of(clearLeaseProfileState()),
              of(storeVehicleTypes(FILTER_VEHICLE_TYPES_CARS)),
              of(storeSearchResults(response.results)),
              of(storeAvailableFilters(response.availableFilters)),
              of(
                storeSearchTotals({
                  vehicleTotal: response.vehicleTotal,
                  pricesTotal: response.pricesTotal,
                  resultsTotal: response.resultsTotal,
                }),
              ),
              of(storePagination(response.pagination)),
              of(storeLeaseType(response.searchRequest.leaseTypes[0])),
              of(toggleVat(response.searchRequest.leaseTypes[0] === FILTER_LEASE_TYPES_PERSONAL)),
              of(removeLoader(LOADER)),
            ),
          ),
          catchError(() =>
            concat(
              of(removeLoader(LOADER)),
              of(
                addError({
                  key: ERROR,
                  message: constants.errorMessages.fetchingDeals,
                }),
              ),
            ),
          ),
        ),
      )
    }),
  )
}

export const searchPageWithReferenceLoadEffect = (action$, state$) => {
  const LOADER = LOADERS.fetchingSearchResults

  return action$.pipe(
    ofType(landedOnCarSearchPageWithReference),
    map(action => action.payload),
    switchMap(url => {
      const queryString = url.split('?')[1]

      const params = {
        reference: url.split('/car-leasing/search/')[1].split('?')[0],
        page:
          !!queryString && queryString.includes('page=')
            ? Number(queryString.split('page=')[1].split('&')[0])
            : 1,
      }

      const service = () =>
        queryString === undefined || !queryString.includes('page=')
          ? getSearchWithReferenceService(params)
          : submitSearchService({
              ...selectSearchParameters(state$.value),
              page:
                !!queryString && queryString.includes('page=')
                  ? Number(queryString.split('page=')[1].split('&')[0])
                  : 1,
            })

      return concat(
        of(addLoader(LOADER)),
        from(service()).pipe(
          switchMap(response =>
            concat(
              of(clearLeaseProfileState()),
              of(storeSearchResults(response.results)),
              of(storeAvailableFilters(response.availableFilters)),
              of(
                storeSearchTotals({
                  vehicleTotal: response.vehicleTotal,
                  pricesTotal: response.pricesTotal,
                  resultsTotal: response.resultsTotal,
                }),
              ),
              of(storePagination(response.pagination)),
              of(hydrateSearchParameters(response.searchRequest)),
              of(storeLeaseType(response.searchRequest.leaseTypes[0])),
              of(toggleVat(response.searchRequest.leaseTypes[0] === FILTER_LEASE_TYPES_PERSONAL)),
              of(storeVehicleRanges(response.availableFilters.ranges)),
              of(storeVehicleTypes(FILTER_VEHICLE_TYPES_CARS)),
              of(storeIsSearchSaved(true)),
              of(removeLoader(LOADER)),
            ),
          ),
          catchError(
            () => of(storeSearchParameters({ filter: FILTER_PAGE, value: 1 })),
            of(removeLoader(LOADER)),
          ),
        ),
      )
    }),
  )
}

export const searchPageWithPathLoadEffect = (action$, state$) => {
  const LOADER = LOADERS.fetchingSearchResults

  return action$.pipe(
    ofType(landedOnCarSearchPageWithPath),
    map(action => action.payload),
    switchMap(url => {
      const key = url.split('?')[0].substring(1)
      const queryString = url.split('?')[1]
      const params = {
        urlKey: key,
      }

      const service = () =>
        queryString === undefined || !queryString.includes('page=')
          ? getSearchWithPathService(params)
          : submitSearchService({
              ...selectSearchParameters(state$.value),
              vehicleTypes: [FILTER_VEHICLE_TYPES_CARS],
              page:
                !!queryString && queryString.includes('page=')
                  ? Number(queryString.split('page=')[1].split('&')[0])
                  : 1,
            })

      return concat(
        of(addLoader(LOADER)),
        from(service()).pipe(
          switchMap(response =>
            concat(
              of(clearLeaseProfileState()),
              of(storeSearchResults(response.results)),
              of(storeAvailableFilters(response.availableFilters)),
              of(
                storeSearchTotals({
                  vehicleTotal: response.vehicleTotal,
                  pricesTotal: response.pricesTotal,
                  resultsTotal: response.resultsTotal,
                }),
              ),
              of(storePagination(response.pagination)),
              of(storeLeaseType(response.searchRequest.leaseTypes[0])),
              of(storeSavedSearchReference('')),
              of(hydrateSearchParameters(response.searchRequest)),
              of(toggleVat(response.searchRequest.leaseTypes[0] === FILTER_LEASE_TYPES_PERSONAL)),
              of(storeVehicleRanges(response.availableFilters.ranges)),
              of(storeVehicleTypes(FILTER_VEHICLE_TYPES_CARS)),
              of(removeLoader(LOADER)),
            ),
          ),
          catchError(err => {
            if (err.status === 404) {
              return concat(of(removeLoader(LOADER)))
            }

            return concat(
              of(storeSearchParameters({ filter: FILTER_PAGE, value: 1 })),
              of(removeLoader(LOADER)),
            )
          }),
        ),
      )
    }),
  )
}

export const vanSearchPageLoadEffect = (action$, state$) => {
  const LOADER = LOADERS.fetchingSearchResults
  const ERROR = ERRORS.fetchingSearchResults

  return action$.pipe(
    ofType(landedOnVanSearchPage),
    map(action => action.payload),
    switchMap(url => {
      const queryString = url.split('?')[1]
      const isFirstRender = !!queryString && !queryString.includes('page=')
      const existingSearchParams = selectSearchParameters(state$.value)
      const isSearchForCars = existingSearchParams.vehicleTypes === FILTER_VEHICLE_TYPES_CARS

      const searchParameters =
        isFirstRender || isSearchForCars ? INITIAL_STATE.searchParameters : existingSearchParams

      const params = {
        ...searchParameters,
        leaseTypes: [FILTER_LEASE_TYPES_BUSINESS],
        vehicleTypes: [FILTER_VEHICLE_TYPES_VANS],
        page: 1,
      }

      return concat(
        of(addLoader(LOADER)),
        of(removeError(ERROR)),
        of(hydrateSearchParameters(searchParameters)),
        of(storeVehicleTypes(FILTER_VEHICLE_TYPES_VANS)),
        of(storeLeaseType(FILTER_LEASE_TYPES_BUSINESS)),
        of(storeIsSearchSaved(false)),
        from(submitSearchService(params)).pipe(
          switchMap(response =>
            concat(
              of(clearLeaseProfileState()),
              of(storeSearchResults(response.results)),
              of(storeAvailableFilters(response.availableFilters)),
              of(
                storeSearchTotals({
                  vehicleTotal: response.vehicleTotal,
                  pricesTotal: response.pricesTotal,
                  resultsTotal: response.resultsTotal,
                }),
              ),
              of(storePagination(response.pagination)),
              of(toggleVat(false)),
              of(removeLoader(LOADER)),
            ),
          ),
          catchError(() =>
            concat(
              of(removeLoader(LOADER)),
              of(
                addError({
                  key: ERROR,
                  message: constants.errorMessages.fetchingDeals,
                }),
              ),
            ),
          ),
        ),
      )
    }),
  )
}

export const vanSearchPageWithReferenceLoadEffect = action$ => {
  const LOADER = LOADERS.fetchingSearchResults

  return action$.pipe(
    ofType(landedOnVanSearchPageWithReference),
    map(action => action.payload),
    switchMap(url => {
      const queryString = url.split('?')[1]

      const params = {
        reference: url.split('/van-leasing/search/')[1].split('?')[0],
        page:
          !!queryString && queryString.includes('page=')
            ? Number(queryString.split('page=')[1].split('&')[0])
            : 1,
      }

      return concat(
        of(addLoader(LOADER)),
        from(getSearchWithReferenceService(params)).pipe(
          switchMap(response =>
            concat(
              of(clearLeaseProfileState()),
              of(storeSearchResults(response.results)),
              of(storeAvailableFilters(response.availableFilters)),
              of(
                storeSearchTotals({
                  vehicleTotal: response.vehicleTotal,
                  pricesTotal: response.pricesTotal,
                  resultsTotal: response.resultsTotal,
                }),
              ),
              of(storePagination(response.pagination)),
              of(hydrateSearchParameters(response.searchRequest)),
              of(storeLeaseType(FILTER_LEASE_TYPES_BUSINESS)),
              of(storeVehicleTypes(FILTER_VEHICLE_TYPES_VANS)),
              of(toggleVat(false)),
              of(storeVehicleRanges(response.availableFilters.ranges)),
              of(storeIsSearchSaved(true)),
              of(removeLoader(LOADER)),
            ),
          ),
          catchError(
            () => of(storeSearchParameters({ filter: FILTER_PAGE, value: 1 })),
            of(removeLoader(LOADER)),
          ),
        ),
      )
    }),
  )
}

export const vanSearchPageWithPathLoadEffect = (action$, state$) => {
  const LOADER = LOADERS.fetchingSearchResults

  return action$.pipe(
    ofType(landedOnVanSearchPageWithPath),
    map(action => action.payload),
    switchMap(url => {
      const key = url.split('?')[0].substring(1)
      const queryString = url.split('?')[1]
      const params = {
        urlKey: key,
      }

      const service = () =>
        queryString === undefined || !queryString.includes('page=')
          ? getSearchWithPathService(params)
          : submitSearchService({
              ...selectSearchParameters(state$.value),
              vehicleTypes: [FILTER_VEHICLE_TYPES_VANS],
              page:
                !!queryString && queryString.includes('page=')
                  ? Number(queryString.split('page=')[1].split('&')[0])
                  : 1,
            })

      return concat(
        of(addLoader(LOADER)),
        from(service()).pipe(
          switchMap(response =>
            concat(
              of(clearLeaseProfileState()),
              of(storeSearchResults(response.results)),
              of(storeAvailableFilters(response.availableFilters)),
              of(
                storeSearchTotals({
                  vehicleTotal: response.vehicleTotal,
                  pricesTotal: response.pricesTotal,
                  resultsTotal: response.resultsTotal,
                }),
              ),
              of(storePagination(response.pagination)),
              of(storeLeaseType(FILTER_LEASE_TYPES_BUSINESS)),
              of(storeSavedSearchReference('')),
              of(hydrateSearchParameters(response.searchRequest)),
              of(toggleVat(false)),
              of(storeVehicleRanges(response.availableFilters.ranges)),
              of(storeVehicleTypes(FILTER_VEHICLE_TYPES_VANS)),
              of(removeLoader(LOADER)),
            ),
          ),
          catchError(
            () => of(storeSearchParameters({ filter: FILTER_PAGE, value: 1 })),
            of(removeLoader(LOADER)),
          ),
        ),
      )
    }),
  )
}

export const searchPageEffect = combineEpics(
  storeQueryStringEffect,
  searchPageLoadEffect,
  searchPageWithReferenceLoadEffect,
  searchPageWithPathLoadEffect,
  vanSearchPageLoadEffect,
  vanSearchPageWithReferenceLoadEffect,
  vanSearchPageWithPathLoadEffect,
)
