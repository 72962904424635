import { createSelector } from '@reduxjs/toolkit'

export const getSystem = state => state.system

export const selectVersion = createSelector([getSystem], system => system.version)

export const selectQueryString = createSelector([getSystem], system => system.queryString)

export const selectIsFirstRender = createSelector([getSystem], system => system.isFirstRender)

export const selectShowBenefitsModal = createSelector(
  [getSystem],
  system => system.showBenefitsModal,
)

export const selectHasCookieBotLoaded = createSelector(
  [getSystem],
  system => system.hasCookieBotLoaded,
)
