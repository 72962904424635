import { combineEpics, ofType } from 'redux-observable'
import { concat, from, of } from 'rxjs'
import { filter, map, switchMap, debounceTime } from 'rxjs/operators'

import { clearSelectedEngineFilters, clearSelectedSpecFilters } from '../reducers/filter'
import { addLoader, removeLoader } from '../reducers/loader'
import {
  clearAllSearchState,
  clearSelectedCapacityFilters,
  clearSelectedRangeSearchFilter,
  clearSelectedSearchFilter,
  openSearchFilter,
  removeCapacityFilter,
  storeSavedSearchReference,
  storeSearchFilter,
  storeSearchParameters,
  storeSortBy,
  storeTextSearch,
} from '../reducers/search'
import { selectSearchParameters } from '../selectors/search'
import {
  constants,
  FILTER_CAPACITY,
  FILTER_DRIVE_TYPES,
  FILTER_INCLUSIVE_BENEFITS,
  FILTER_MANUFACTURERS,
  FILTER_PAGE,
  FILTER_PERFORMANCE,
  FILTER_ROOF_HEIGHT,
  FILTER_SPEC,
  FILTER_TRANSMISSION_TYPES,
  FILTER_VEHICLE_TAGS,
  FILTER_WHEELBASE,
} from '@/lib/constants'
import { LOADERS } from '@/lib/loaders'
import { getFiltersService } from '@/lib/services/searchService'

export const openSearchFilterEffect = (action$, state$) => {
  const isOnExclusionList = payload =>
    payload === FILTER_TRANSMISSION_TYPES ||
    payload === FILTER_WHEELBASE ||
    payload === FILTER_INCLUSIVE_BENEFITS

  return action$.pipe(
    ofType(openSearchFilter),
    map(action => action.payload),
    filter(payload => !isOnExclusionList(payload)),
    switchMap(filter => {
      const search = selectSearchParameters(state$.value)
      const LOADER = LOADERS.fetchingSearchFilter[filter]

      return concat(
        of(addLoader(LOADER)),
        from(getFiltersService({ filter, search })).pipe(
          switchMap(response =>
            concat(
              of(
                storeSearchFilter({
                  filter,
                  params: response,
                }),
              ),
              of(removeLoader(LOADER)),
            ),
          ),
        ),
      )
    }),
  )
}

export const openTransmissionFilterEffect = (action$, state$) => {
  const LOADER = LOADERS.fetchingSearchFilter.transmissionTypes
  const LOADER_TWO = LOADERS.fetchingSearchFilter.driveTypes

  return action$.pipe(
    ofType(openSearchFilter),
    map(action => action.payload),
    filter(payload => payload === FILTER_TRANSMISSION_TYPES),
    switchMap(() => {
      const search = selectSearchParameters(state$.value)

      return concat(
        of(addLoader(LOADER)),
        from(getFiltersService({ filter: FILTER_TRANSMISSION_TYPES, search })).pipe(
          switchMap(response =>
            concat(
              of(storeSearchFilter({ filter: FILTER_TRANSMISSION_TYPES, params: response })),
              of(removeLoader(LOADER)),
            ),
          ),
        ),
        of(addLoader(LOADER_TWO)),
        from(getFiltersService({ filter: FILTER_DRIVE_TYPES, search })).pipe(
          switchMap(response =>
            concat(
              of(storeSearchFilter({ filter: FILTER_DRIVE_TYPES, params: response })),
              of(removeLoader(LOADER_TWO)),
            ),
          ),
        ),
      )
    }),
  )
}

export const openWheelbaseFilterEffect = (action$, state$) => {
  const LOADER = LOADERS.fetchingSearchFilter.wheelbase
  const LOADER_TWO = LOADERS.fetchingSearchFilter.roofHeight

  return action$.pipe(
    ofType(openSearchFilter),
    map(action => action.payload),
    filter(payload => payload === FILTER_WHEELBASE),
    switchMap(() => {
      const search = selectSearchParameters(state$.value)

      return concat(
        of(addLoader(LOADER)),
        from(getFiltersService({ filter: FILTER_WHEELBASE, search })).pipe(
          switchMap(response =>
            concat(
              of(storeSearchFilter({ filter: FILTER_WHEELBASE, params: response })),
              of(removeLoader(LOADER)),
            ),
          ),
        ),
        of(addLoader(LOADER_TWO)),
        from(getFiltersService({ filter: FILTER_ROOF_HEIGHT, search })).pipe(
          switchMap(response =>
            concat(
              of(storeSearchFilter({ filter: FILTER_ROOF_HEIGHT, params: response })),
              of(removeLoader(LOADER_TWO)),
            ),
          ),
        ),
      )
    }),
  )
}

export const getSearchResultsAfterRemovingFilterEffect = action$ =>
  action$.pipe(
    ofType(clearSelectedSearchFilter),
    filter(payload => payload !== FILTER_MANUFACTURERS && payload !== FILTER_VEHICLE_TAGS),
    switchMap(() => of(storeSearchParameters({ filter: FILTER_PAGE, value: 1 }))),
  )

export const removeSavedSearchRefWhenSearchPillClearsEffect = action$ =>
  action$.pipe(
    ofType(clearSelectedSearchFilter),
    map(action => action.payload),
    filter(payload => payload === FILTER_VEHICLE_TAGS),
    switchMap(() =>
      concat(
        of(storeSavedSearchReference('')),
        of(storeSearchParameters({ filter: FILTER_PAGE, value: 1 })),
      ),
    ),
  )

export const removeRangesWhenMakeClearsEffect = action$ =>
  action$.pipe(
    ofType(clearSelectedSearchFilter),
    map(action => action.payload),
    filter(payload => payload === FILTER_MANUFACTURERS),
    switchMap(() =>
      concat(
        of(clearSelectedRangeSearchFilter()),
        of(storeSearchParameters({ filter: FILTER_PAGE, value: 1 })),
      ),
    ),
  )

export const removeSelectedEngineFiltersEffect = action$ =>
  action$.pipe(
    ofType(clearSelectedSearchFilter),
    map(action => action.payload),
    filter(payload => payload === FILTER_PERFORMANCE),
    switchMap(() => concat(of(clearSelectedEngineFilters()))),
  )

export const removeSelectedCapacityFiltersEffect = action$ =>
  action$.pipe(
    ofType(clearSelectedSearchFilter),
    map(action => action.payload),
    filter(payload => payload === FILTER_CAPACITY),
    switchMap(() => concat(of(clearSelectedCapacityFilters()))),
  )

export const removeSelectedSpecFiltersEffect = action$ =>
  action$.pipe(
    ofType(clearSelectedSearchFilter),
    map(action => action.payload),
    filter(payload => payload === FILTER_SPEC),
    switchMap(() => concat(of(clearSelectedSpecFilters()))),
  )

export const getSearchResultsAfterClearingFiltersEffect = action$ =>
  action$.pipe(
    ofType(clearAllSearchState),
    switchMap(() => of(storeSearchParameters({ filter: FILTER_PAGE, value: 1 }))),
    debounceTime(constants.searchDebounceDelay),
  )

export const getSearchResultsAfterChangingSortByEffect = action$ =>
  action$.pipe(
    ofType(storeSortBy),
    switchMap(() => of(storeSearchParameters({ filter: FILTER_PAGE, value: 1 }))),
  )

export const getSearchResultsAfterRemovingCapacityEffect = action$ =>
  action$.pipe(
    ofType(removeCapacityFilter),
    switchMap(() => of(storeSearchParameters({ filter: FILTER_PAGE, value: 1 }))),
  )

export const getSearchResultsForTextSearchEffect = action$ =>
  action$.pipe(
    ofType(storeTextSearch),
    map(() => storeSearchParameters({ filter: FILTER_PAGE, value: 1 })),
  )

export const searchFiltersEffect = combineEpics(
  openSearchFilterEffect,
  openTransmissionFilterEffect,
  openWheelbaseFilterEffect,
  getSearchResultsAfterRemovingFilterEffect,
  removeSavedSearchRefWhenSearchPillClearsEffect,
  removeRangesWhenMakeClearsEffect,
  removeSelectedEngineFiltersEffect,
  removeSelectedCapacityFiltersEffect,
  removeSelectedSpecFiltersEffect,
  getSearchResultsAfterClearingFiltersEffect,
  getSearchResultsAfterChangingSortByEffect,
  getSearchResultsAfterRemovingCapacityEffect,
  getSearchResultsForTextSearchEffect,
)
