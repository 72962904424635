import { createSlice } from '@reduxjs/toolkit'

export const INITIAL_STATE = {
  list: [],
}

const loaderSlice = createSlice({
  name: 'loader',
  initialState: INITIAL_STATE,
  reducers: {
    addLoader: (state, action) => {
      state.list = [...state.list, action.payload]
    },
    removeLoader: (state, action) => {
      state.list = [...state.list.filter(item => item !== action.payload)]
    },
    clearLoaders: () => {
      return INITIAL_STATE
    },
  },
})

export const { addLoader, removeLoader, clearLoaders } = loaderSlice.actions

export default loaderSlice.reducer
