import { combineEpics, ofType } from 'redux-observable'
import { concat, from, of } from 'rxjs'
import { catchError, map, switchMap } from 'rxjs/operators'

import { constants } from '@/lib/constants'
import { ERRORS } from '@/lib/errors'
import { LOADERS } from '@/lib/loaders'
import {
  getUserMarketingEmailPreferencesService,
  getUserMarketingSmsPreferencesService,
  getUserPriceAlertPreferencesService,
  setUserMarketingEmailPreferencesService,
  setUserMarketingSmsPreferencesService,
  setUserPriceAlertPreferencesService,
} from '@/lib/services/userService'
import { checkForUser } from '@/lib/utilities/account'
import { addError, removeError } from '@/store/reducers/error'
import { addLoader, removeLoader } from '@/store/reducers/loader'
import {
  getPriceAlertPreferences,
  updatePriceAlertPreferences,
  storeUserPriceAlertPreference,
  getMarketingEmailPreferences,
  storeUserMarketingEmailPreference,
  getMarketingSmsPreferences,
  storeUserMarketingSmsPreference,
  updateMarketingSmsPreferences,
  updateMarketingEmailPreferences,
} from '@/store/reducers/user'

export const getUserPriceAlertPreferencesEffect = action$ => {
  const LOADER = LOADERS.fetchingAccountInfo
  const API_ERROR = ERRORS.fetchingAccountInfo
  const USER_ERROR = ERRORS.checkForUser

  return action$.pipe(
    ofType(getPriceAlertPreferences),
    switchMap(() => {
      return concat(
        of(addLoader(LOADER)),
        of(removeError(API_ERROR)),
        of(removeError(USER_ERROR)),
        from(checkForUser()).pipe(
          switchMap(token => {
            return from(getUserPriceAlertPreferencesService({ token })).pipe(
              switchMap(response => {
                return concat(of(storeUserPriceAlertPreference(response)), of(removeLoader(LOADER)))
              }),
              catchError(() => {
                return concat(
                  of(removeLoader(LOADER)),
                  of(
                    addError({
                      key: API_ERROR,
                      message: constants.errorMessages.fetchingMarketingPreferences,
                    }),
                  ),
                )
              }),
            )
          }),
          catchError(() => {
            return concat(
              of(removeLoader(LOADER)),
              of(
                addError({
                  key: USER_ERROR,
                  message: constants.errorMessages.userCredentials,
                }),
              ),
            )
          }),
        ),
      )
    }),
  )
}

export const saveUserPriceAlertPreferencesEffect = action$ => {
  const LOADER = LOADERS.updatingMarketingPreferences
  const API_ERROR = ERRORS.updatingMarketingPreferences
  const USER_ERROR = ERRORS.checkForUser

  return action$.pipe(
    ofType(updatePriceAlertPreferences),
    map(action => action.payload),
    switchMap(params => {
      return concat(
        of(addLoader(LOADER)),
        of(removeError(API_ERROR)),
        of(removeError(USER_ERROR)),
        from(checkForUser()).pipe(
          switchMap(token => {
            return from(
              setUserPriceAlertPreferencesService({
                token,
                value: params.value,
              }),
            ).pipe(
              switchMap(() => {
                return of(removeLoader(LOADER))
              }),
              catchError(() => {
                return concat(
                  of(removeLoader(LOADER)),
                  of(
                    addError({
                      key: API_ERROR,
                      message: constants.errorMessages.updatingMarketingPreferences,
                    }),
                  ),
                )
              }),
            )
          }),
          catchError(() => {
            return concat(
              of(removeLoader(LOADER)),
              of(
                addError({
                  key: USER_ERROR,
                  message: constants.errorMessages.userCredentials,
                }),
              ),
            )
          }),
        ),
      )
    }),
  )
}

export const getUserMarketingEmailPreferencesEffect = action$ => {
  const LOADER = LOADERS.fetchingAccountInfo
  const API_ERROR = ERRORS.fetchingAccountInfo
  const USER_ERROR = ERRORS.checkForUser

  return action$.pipe(
    ofType(getMarketingEmailPreferences),
    switchMap(() => {
      return concat(
        of(addLoader(LOADER)),
        of(removeError(API_ERROR)),
        of(removeError(USER_ERROR)),
        from(checkForUser()).pipe(
          switchMap(token => {
            return from(getUserMarketingEmailPreferencesService({ token })).pipe(
              switchMap(response => {
                return concat(
                  of(storeUserMarketingEmailPreference(response)),
                  of(removeLoader(LOADER)),
                )
              }),
              catchError(() => {
                return concat(
                  of(removeLoader(LOADER)),
                  of(
                    addError({
                      key: API_ERROR,
                      message: constants.errorMessages.fetchingMarketingPreferences,
                    }),
                  ),
                )
              }),
            )
          }),
          catchError(() => {
            return concat(
              of(removeLoader(LOADER)),
              of(
                addError({
                  key: USER_ERROR,
                  message: constants.errorMessages.userCredentials,
                }),
              ),
            )
          }),
        ),
      )
    }),
  )
}

export const saveUserMarketingEmailPreferencesEffect = action$ => {
  const LOADER = LOADERS.updatingMarketingPreferences
  const API_ERROR = ERRORS.updatingMarketingPreferences
  const USER_ERROR = ERRORS.checkForUser

  return action$.pipe(
    ofType(updateMarketingEmailPreferences),
    map(action => action.payload),
    switchMap(params => {
      return concat(
        of(addLoader(LOADER)),
        of(removeError(API_ERROR)),
        of(removeError(USER_ERROR)),
        from(checkForUser()).pipe(
          switchMap(token => {
            return from(
              setUserMarketingEmailPreferencesService({
                token,
                value: params.value,
              }),
            ).pipe(
              switchMap(() => {
                return of(removeLoader(LOADER))
              }),
              catchError(() => {
                return concat(
                  of(removeLoader(LOADER)),
                  of(
                    addError({
                      key: API_ERROR,
                      message: constants.errorMessages.updatingMarketingPreferences,
                    }),
                  ),
                )
              }),
            )
          }),
          catchError(() => {
            return concat(
              of(removeLoader(LOADER)),
              of(
                addError({
                  key: USER_ERROR,
                  message: constants.errorMessages.userCredentials,
                }),
              ),
            )
          }),
        ),
      )
    }),
  )
}

export const getUserMarketingSmsPreferencesEffect = action$ => {
  const LOADER = LOADERS.fetchingAccountInfo
  const API_ERROR = ERRORS.fetchingAccountInfo
  const USER_ERROR = ERRORS.checkForUser

  return action$.pipe(
    ofType(getMarketingSmsPreferences),
    switchMap(() => {
      return concat(
        of(addLoader(LOADER)),
        of(removeError(API_ERROR)),
        of(removeError(USER_ERROR)),
        from(checkForUser()).pipe(
          switchMap(token => {
            return from(getUserMarketingSmsPreferencesService({ token })).pipe(
              switchMap(response => {
                return concat(
                  of(storeUserMarketingSmsPreference(response)),
                  of(removeLoader(LOADER)),
                )
              }),
              catchError(() => {
                return concat(
                  of(removeLoader(LOADER)),
                  of(
                    addError({
                      key: API_ERROR,
                      message: constants.errorMessages.fetchingMarketingPreferences,
                    }),
                  ),
                )
              }),
            )
          }),
          catchError(() => {
            return concat(
              of(removeLoader(LOADER)),
              of(
                addError({
                  key: USER_ERROR,
                  message: constants.errorMessages.userCredentials,
                }),
              ),
            )
          }),
        ),
      )
    }),
  )
}

export const saveUserMarketingSmsPreferencesEffect = action$ => {
  const LOADER = LOADERS.updatingMarketingPreferences
  const API_ERROR = ERRORS.updatingMarketingPreferences
  const USER_ERROR = ERRORS.checkForUser

  return action$.pipe(
    ofType(updateMarketingSmsPreferences),
    map(action => action.payload),
    switchMap(params => {
      return concat(
        of(addLoader(LOADER)),
        of(removeError(API_ERROR)),
        of(removeError(USER_ERROR)),
        from(checkForUser()).pipe(
          switchMap(token => {
            return from(
              setUserMarketingSmsPreferencesService({
                token,
                value: params.value,
              }),
            ).pipe(
              switchMap(() => {
                return of(removeLoader(LOADER))
              }),
              catchError(() => {
                return concat(
                  of(removeLoader(LOADER)),
                  of(
                    addError({
                      key: API_ERROR,
                      message: constants.errorMessages.updatingMarketingPreferences,
                    }),
                  ),
                )
              }),
            )
          }),
          catchError(() => {
            return concat(
              of(removeLoader(LOADER)),
              of(
                addError({
                  key: USER_ERROR,
                  message: constants.errorMessages.userCredentials,
                }),
              ),
            )
          }),
        ),
      )
    }),
  )
}

export const userPreferencesEffect = combineEpics(
  getUserPriceAlertPreferencesEffect,
  saveUserPriceAlertPreferencesEffect,
  getUserMarketingEmailPreferencesEffect,
  saveUserMarketingEmailPreferencesEffect,
  getUserMarketingSmsPreferencesEffect,
  saveUserMarketingSmsPreferencesEffect,
)
