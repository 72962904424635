import { createSlice } from '@reduxjs/toolkit'

export const INITIAL_STATE = {
  availableProfiles: {
    availableTerms: [],
    availableMileages: [],
    availableInitialPaymentInMonths: [],
    availableLeaseTypes: [],
    availableStockStatus: [],
  },
  activeProfile: {
    vehicleId: null,
    leaseType: null,
    term: null,
    mileage: null,
    initialPaymentInMonths: null,
    stockStatus: null,
  },
}

const leaseProfileSlice = createSlice({
  name: 'leaseProfile',
  initialState: INITIAL_STATE,
  reducers: {
    storeAvailableLeaseProfiles: (state, action) => {
      state.availableProfiles = action.payload
    },
    storeActiveLeaseProfile: (state, action) => {
      state.activeProfile = action.payload
    },
    clearLeaseProfileState: () => {
      return INITIAL_STATE
    },
  },
})

export const {
  storeAvailableLeaseProfiles,
  storeActiveLeaseProfile,
  clearLeaseProfileState,
} = leaseProfileSlice.actions

export default leaseProfileSlice.reducer
