import { createAction, createSlice } from '@reduxjs/toolkit'

/* eslint-disable camelcase */
export const INITIAL_STATE = {
  isAuthenticated: false,
  isVerified: false,
  id: null,
  userName: '',
  email: '',
  phoneNumber: '',
  firstName: '',
  lastName: '',
  dateCreated: '',
  preferences: {
    marketingEmail: false,
    marketingSms: false,
    priceAlert: false,
  },
  referralCode: null,
  redirectUrl: '/',
  usedSocialLogin: false,
  rewardsHistory: [],
  avatar: '',
  isRewardSubmissionSuccessful: false,
  visitorKey: null,
  visitorKeyTimestamp: null,
  experiment: {
    name: '',
    group: '',
  },
  touchpoints: {
    firstTouch: {
      utm_source: null,
      utm_medium: null,
      utm_campaign: null,
      llit_code: null,
      irclickid: null,
      gclid: null,
    },
    lastTouch: {
      utm_source: null,
      utm_medium: null,
      utm_campaign: null,
      llit_code: null,
      irclickid: null,
      gclid: null,
    },
  },
  leadReference: null,
  enquiryReference: null,
  enquiredDeals: [],
  dotDigitalId: null,
}

/* eslint-enable camelcase */

const userSlice = createSlice({
  name: 'user',
  initialState: INITIAL_STATE,
  reducers: {
    storeIsUserAuthenticated: (state, action) => {
      state.isAuthenticated = action.payload
    },
    storeIsUserVerified: (state, action) => {
      state.isVerified = action.payload
    },
    storeUserName: (state, action) => {
      state.userName = action.payload
    },
    storeUserDetails: (state, action) => {
      return {
        ...state,
        ...action.payload,
      }
    },
    storeUserMarketingEmailPreference: (state, action) => {
      state.preferences.marketingEmail = action.payload.optedIn
    },
    storeUserMarketingSmsPreference: (state, action) => {
      state.preferences.marketingSms = action.payload.optedIn
    },
    storeUserPriceAlertPreference: (state, action) => {
      state.preferences.priceAlert = action.payload.optedIn
    },
    storeUserReferralCode: (state, action) => {
      state.referralCode = action.payload
    },
    storeUserDotDigitalId: (state, action) => {
      state.dotDigitalId = action.payload
    },
    storeUsedSocialLogin: (state, action) => {
      state.usedSocialLogin = action.payload
    },
    storeRedirectUrl: (state, action) => {
      state.redirectUrl = action.payload
    },
    clearRedirectUrl: state => {
      state.redirectUrl = INITIAL_STATE.redirectUrl
    },
    clearUserStateExceptRedirectUrl: state => {
      return {
        ...INITIAL_STATE,
        redirectUrl: state.redirectUrl,
        usedSocialLogin: state.usedSocialLogin,
        visitorKey: state.visitorKey,
        visitorKeyTimestamp: state.visitorKeyTimestamp,
        isVerified: state.isVerified,
        experiment: state.experiment,
        touchpoints: state.touchpoints,
        dotDigitalId: state.dotDigitalId,
      }
    },
    storeRewardsHistory: (state, action) => {
      state.rewardsHistory = action.payload
    },
    storeAvatar: (state, action) => {
      state.avatar = action.payload
    },
    storeIsRewardSubmissionSuccessful: (state, action) => {
      state.isRewardSubmissionSuccessful = action.payload
    },
    storeVisitorKey: (state, action) => {
      state.visitorKey = action.payload.visitorKey
      state.visitorKeyTimestamp = action.payload.timestamp
        ? action.payload.timestamp
        : state.visitorKeyTimestamp
    },
    storeExperiment: (state, action) => {
      state.experiment = {
        name: action.payload.experimentName,
        group: action.payload.experimentGroup,
      }
    },
    storeFirstTouch: (state, action) => {
      state.touchpoints.firstTouch = action.payload
    },
    storeLastTouch: (state, action) => {
      state.touchpoints.lastTouch = action.payload
    },
    storeLeadReference: (state, action) => {
      state.leadReference = action.payload
    },
    clearLeadReference: state => {
      state.leadReference = null
    },
    storeEnquiryReference: (state, action) => {
      state.enquiryReference = action.payload
    },
    clearEnquiryReference: state => {
      state.enquiryReference = null
    },
    storeEnquiredDeals: (state, action) => {
      state.enquiredDeals = [...state.enquiredDeals, action.payload]
    },
    saveUserDetailsToStore: (state, action) => {
      return {
        ...state,
        ...action.payload,
      }
    },
    clearUserState: () => {
      return { ...INITIAL_STATE }
    },
  },
})

export const getAccountInfo = createAction('user/getAccountInfo')
export const checkIsVehicleSaved = createAction('user/checkIsVehicleSaved')
export const removePriceAlert = createAction('user/removePriceAlert', payload => ({
  payload,
}))
export const addPriceAlert = createAction('user/addPriceAlert', payload => ({
  payload,
}))
export const getPriceAlertPreferences = createAction('user/getPriceAlertPreferences')
export const updatePriceAlertPreferences = createAction(
  'user/updatePriceAlertPreferences',
  payload => ({
    payload,
  }),
)
export const getMarketingEmailPreferences = createAction('user/getMarketingEmailPreferences')
export const updateMarketingEmailPreferences = createAction(
  'user/updateMarketingEmailPreferences',
  payload => ({
    payload,
  }),
)
export const getMarketingSmsPreferences = createAction('user/getMarketingSmsPreferences')
export const updateMarketingSmsPreferences = createAction(
  'user/updateMarketingSmsPreferences',
  payload => ({
    payload,
  }),
)
export const sendEnquiry = createAction('user/sendEnquiry', payload => ({ payload }))
export const sendSalarySacrificeEnquiry = createAction('user/sendSalarySacrificeEnquiry')
export const getRewardsHistory = createAction('user/getRewardsHistory')
export const sendLeadDetails = createAction('user/sendLeadDetails')
export const sendLeadVerificationCode = createAction('user/sendLeadVerificationCode')
export const storeQueryString = createAction('user/storeQueryString')
export const sendAlternativeLeadDetails = createAction('user/sendAlternativeLeadDetails')
export const sendAlternativeLeadVerificationCode = createAction(
  'user/sendAlternativeLeadVerificationCode',
)
export const saveDealDetailsForRewards = createAction(
  'user/saveDealDetailsForRewards',
  ({ brokerId, promoCode, orderId, phoneNumber }) => {
    return {
      payload: {
        brokerId,
        promoCode,
        phoneNumber,
        orderId,
      },
    }
  },
)

export const {
  storeAvatar,
  clearEnquiryReference,
  clearLeadReference,
  clearRedirectUrl,
  clearUserState,
  clearUserStateExceptRedirectUrl,
  saveUserDetailsToStore,
  storeEnquiryReference,
  storeExperiment,
  storeFirstTouch,
  storeLastTouch,
  storeUserDotDigitalId,
  storeEnquiredDeals,
  storeIsRewardSubmissionSuccessful,
  storeLeadReference,
  storeIsUserVerified,
  storeIsUserAuthenticated,
  storeRedirectUrl,
  storeRewardsHistory,
  storeUserName,
  storeUserDetails,
  storeUserReferralCode,
  storeUsedSocialLogin,
  storePriceAlertExists,
  storeVisitorKey,
  storeUserMarketingEmailPreference,
  storeUserMarketingSmsPreference,
  storeUserPriceAlertPreference,
} = userSlice.actions

export default userSlice.reducer
