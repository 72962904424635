import { createSelector } from 'reselect'

const getUser = state => state.user

export const selectIsAuthenticated = createSelector([getUser], user => user.isAuthenticated)

export const selectIsVerified = createSelector([getUser], user => user.isVerified)

export const selectUsersUsername = createSelector([getUser], user => user.userName)

export const selectUserEmail = createSelector([getUser], user => user.email)

export const selectUserId = createSelector([getUser], user => user.id)

export const selectUsersFullName = createSelector([getUser], user => ({
  firstName: user.firstName,
  lastName: user.lastName,
}))

export const selectUsersFirstName = createSelector([getUser], user => user.firstName)

export const selectUsersLastName = createSelector([getUser], user => user.lastName)

export const selectUsersPhoneNumber = createSelector([getUser], user => user.phoneNumber)

export const selectUserReferralCode = createSelector([getUser], user => user.referralCode)

export const selectRedirectUrl = createSelector([getUser], user => user.redirectUrl)

export const selectUsedSocialLogin = createSelector([getUser], user => user.usedSocialLogin)

export const selectFullRewardsHistory = createSelector([getUser], user => user.rewardsHistory)

export const selectDateCreated = createSelector([getUser], user => user.dateCreated)

export const selectAvatar = createSelector([getUser], user => user.avatar)

export const selectDoesPriceAlertExist = createSelector([getUser], user => user.doesPriceAlertExist)

export const selectIsRewardSubmissionSuccessful = createSelector(
  [getUser],
  user => user.isRewardSubmissionSuccessful,
)

export const selectUsersVisitorKey = createSelector([getUser], user => user.visitorKey)

export const selectUsersVisitorKeyTimestamp = createSelector(
  [getUser],
  user => user.visitorKeyTimestamp,
)

export const selectExperiment = createSelector([getUser], user => user.experiment)

export const selectTouchpoints = createSelector([getUser], user => user.touchpoints)

export const selectFirstTouchpoint = createSelector([selectTouchpoints], touchpoints => {
  let tp = {}
  let parsedParam = ''

  const stringFields = [
    'utm_source',
    'utm_campaign',
    'utm_medium',
    'llit_code',
    'irclickid',
    'gclid',
  ]

  for (const [key, value] of Object.entries(touchpoints.firstTouch)) {
    try {
      parsedParam = JSON.parse(value)
    } catch (e) {
      // nothing happens
    }

    if (parsedParam && Array.isArray(parsedParam)) {
      tp[key] = parsedParam[0]
    } else if (stringFields.includes(key) && Array.isArray(value)) {
      tp[key] = value[0]
    } else {
      tp[key] = value
    }
  }

  return tp
})

export const selectLastTouchpoint = createSelector([selectTouchpoints], touchpoints => {
  let tp = {}
  let parsedParam = ''

  const stringFields = [
    'utm_source',
    'utm_campaign',
    'utm_medium',
    'llit_code',
    'irclickid',
    'gclid',
  ]

  for (const [key, value] of Object.entries(touchpoints.lastTouch)) {
    try {
      parsedParam = JSON.parse(value)
    } catch (e) {
      // nothing happens
    }

    if (Array.isArray(parsedParam)) {
      tp[key] = parsedParam[0]
    } else if (stringFields.includes(key) && Array.isArray(value)) {
      tp[key] = value[0]
    } else {
      tp[key] = value
    }
  }

  return tp
})

export const selectLeadReference = createSelector([getUser], user => user.leadReference)

export const selectEnquiryReference = createSelector([getUser], user => user.enquiryReference)

export const selectEnquiredDealsList = createSelector([getUser], user => user.enquiredDeals)

export const selectEnquiredDealIdExists = dealId => state =>
  selectEnquiredDealsList(state).filter(item => item === dealId).length === 1

export const selectUsersFormDetails = createSelector([getUser], user => ({
  lastName: user.lastName,
  firstName: user.firstName,
  emailAddress: user.email,
}))

export const selectUserAccountDetails = createSelector([getUser], user => ({
  id: user.id,
  email: user.email,
  firstName: user.firstName,
  lastName: user.lastName,
  phoneNumber: user.phoneNumber,
}))

export const selectUsersDotDigitalId = createSelector([getUser], user => user.dotDigitalId)

export const selectUserPriceAlertPreference = createSelector(
  [getUser],
  user => user.preferences.priceAlert || false,
)

export const selectUserMarketingEmailPreference = createSelector(
  [getUser],
  user => user.preferences.marketingEmail || false,
)

export const selectUserMarketingSmsPreference = createSelector(
  [getUser],
  user => user.preferences.marketingSms || false,
)
