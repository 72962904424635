export const POST_ANALYTICS_EVENT = '@@event/POST_ANALYTICS_EVENT'
export const SEND_SEARCH_ANALYTICS = '@@event/SEND_SEARCH_ANALYTICS'
export const SEND_LEASE_PROFILE_ANALYTICS = '@@event/SEND_LEASE_PROFILE_ANALYTICS'
export const SEND_TO_DATA_LAYER = '@@event/SEND_TO_DATA_LAYER'

export const postAnalyticsEvent = analyticsData => ({
  type: POST_ANALYTICS_EVENT,
  payload: analyticsData,
})

export const sendSearchAnalytics = url => ({
  type: SEND_SEARCH_ANALYTICS,
  payload: url,
})

export const sendToDataLayer = analyticsData => ({
  type: SEND_TO_DATA_LAYER,
  payload: analyticsData,
})
