import { endpointKeys } from '../endpoints'
import api from './restService'

const post = async (endpointKey, params, urlParams = {}) => {
  try {
    const response = await api.fetchWithTimeout(getUrl(endpointKey, urlParams), {
      method: 'POST',
      headers: [['Content-Type', 'application/json']],
      body: JSON.stringify(params),
    })
    return api.getResponse(response)
  } catch (err) {
    return api.getError(err)
  }
}

const getUrl = (endpointKey, urlParams = {}, query = null) => {
  const serverUrl = `${process.env.NEXT_PUBLIC_EVENT_API_URL}${endpointKey}${
    urlParams.length ? urlParams : ''
  }`
  const queryString = query ? createQueryString(query) : ''
  return `${serverUrl}${queryString}`
}

const createQueryString = query => {
  const queryString = Object.keys(query)
    .filter(key => query[key] != null && query[key].length !== 0 && key !== 'payload')
    .map(key => key + '=' + query[key])
    .join('&')

  let eventObject = {}

  const event = query.payload
    ? Object.keys(query.payload)
        .filter(key => query.payload[key] != null && query.payload[key].length !== 0)
        .map(key => (eventObject[key] = query.payload[key]))
    : ''

  const eventQueryString = encodeURIComponent(JSON.stringify(eventObject))

  return !!queryString || !!event
    ? `?${queryString ? queryString : ''}${!!queryString && !!event ? '&' : ''}${
        event ? `payload=${eventQueryString}` : ''
      }`
    : ''
}

export const postAnalyticsEventService = async params => {
  return await post(endpointKeys.postAnalyticsEvent, { ...params })
}
