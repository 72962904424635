import { createSlice } from '@reduxjs/toolkit'

export const INITIAL_STATE = {
  list: [],
}

const errorSlice = createSlice({
  name: 'error',
  initialState: INITIAL_STATE,
  reducers: {
    addError: (state, action) => {
      if (state.list.findIndex(item => item.key === action.payload.key) === -1) {
        state.list = [...state.list, action.payload]
      }
    },
    removeError: (state, action) => {
      state.list = state.list.filter(item => item.key !== action.payload)
    },
    clearErrors: () => {
      return INITIAL_STATE
    },
  },
})

export const { addError, removeError, clearErrors } = errorSlice.actions

export default errorSlice.reducer
