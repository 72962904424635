import { combineEpics, ofType } from 'redux-observable'
import { concat, from, of } from 'rxjs'
import { catchError, map, switchMap } from 'rxjs/operators'

import { ERRORS } from '../../lib/errors'
import { LOADERS } from '../../lib/loaders'
import { getAvailableLeaseProfileFiltersService } from '../../lib/services/vehicleService'
import { addError } from '../reducers/error'
import { addLoader, removeLoader } from '../reducers/loader'
import {
  storeAvailableLeaseProfiles,
  storeActiveLeaseProfile,
} from '../reducers/leaseProfile'

export const getAvailableLeaseProfileFiltersEffect = action$ => {
  const LOADER = LOADERS.fetchingAvailableLeaseProfiles
  const ERROR = ERRORS.fetchingAvailableLeaseProfiles

  return action$.pipe(
    ofType(storeActiveLeaseProfile),
    map(action => action.payload),
    switchMap(leaseProfile =>
      concat(
        of(addLoader(LOADER)),
        from(getAvailableLeaseProfileFiltersService({ ...leaseProfile })).pipe(
          switchMap(response =>
            concat(of(storeAvailableLeaseProfiles(response)), of(removeLoader(LOADER))),
          ),
          catchError(() =>
            concat(
              of(removeLoader(LOADER)),
              of(
                addError({
                  key: ERROR,
                  message: 'There has been an issue fetching available lease profiles',
                }),
              ),
            ),
          ),
        ),
      ),
    ),
  )
}

export const leaseProfileEffect = combineEpics(getAvailableLeaseProfileFiltersEffect)
