import { captureException } from '@sentry/nextjs'

import { InternalServerError } from '../errors'
import api from './restService'

const post = async (endpointKey, params) => {
  try {
    return await api
      .fetchWithTimeout(getUrl(endpointKey), {
        method: 'POST',
        headers: [['Content-Type', 'application/json']],
        body: JSON.stringify(params),
      })
      .then(response => {
        return api.getResponse(response)
      })
  } catch (error) {
    if (error.status === 500) {
      captureException(
        InternalServerError('LeadService POST Failure', {
          extra: {
            error,
            params,
            endpointKey,
            body: JSON.stringify(params),
          },
        }),
      )
    }
    return api.getError(error)
  }
}

const getUrl = endpointKey => {
  return `${process.env.NEXT_PUBLIC_LEAD_API_URL}${endpointKey}`
}

const leadService = {
  post,
}

export default leadService
