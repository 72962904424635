import { createSlice } from '@reduxjs/toolkit'

export const INITIAL_STATE = {
  exteriorImages: [],
  interiorImages: [],
  selectedPaintOption: null,
  paintOptions: [],
  paintOptionVehicle: null,
}

const vehicleSlice = createSlice({
  name: 'vehicle',
  initialState: INITIAL_STATE,
  reducers: {
    storeExteriorImages: (state, action) => {
      state.exteriorImages = action.payload
    },
    storeInteriorImages: (state, action) => {
      state.interiorImages = action.payload
    },
    clearImages: state => {
      state.exteriorImages = INITIAL_STATE.exteriorImages
      state.interiorImages = INITIAL_STATE.interiorImages
    },
    storeSelectedPaintOption: (state, action) => {
      state.selectedPaintOption = action.payload
    },
    clearSelectedPaintOption: state => {
      state.selectedPaintOption = INITIAL_STATE.selectedPaintOption
    },
    storePaintOptions: (state, action) => {
      state.paintOptions = action.payload

      if (action.payload.filter(paint => paint.id === state.selectedPaintOption?.id).length === 0) {
        state.selectedPaintOption = INITIAL_STATE.selectedPaintOption
      }
    },
    clearPaintOptions: state => {
      state.paintOptions = INITIAL_STATE.paintOptions
    },
    storePaintOptionVehicle: (state, action) => {
      state.paintOptionVehicle = action.payload
    },
    clearPaintOptionVehicle: state => {
      state.paintOptionVehicle = INITIAL_STATE.paintOptionVehicle
    },
  },
})

export const {
  storeExteriorImages,
  storeInteriorImages,
  clearImages,
  storeSelectedPaintOption,
  clearSelectedPaintOption,
  storePaintOptions,
  clearPaintOptions,
  storePaintOptionVehicle,
  clearPaintOptionVehicle,
} = vehicleSlice.actions

export default vehicleSlice.reducer
