import { createSlice } from '@reduxjs/toolkit'

export const INITIAL_STATE = {
  list: [],
}

const supplierSlice = createSlice({
  name: 'supplier',
  initialState: INITIAL_STATE,
  reducers: {
    storeSupplierListForRewards: (state, action) => {
      state.list = action.payload
    },
    getSupplierListForRewards: () => {},
  },
})

export const {
  storeSupplierListForRewards,
  getSupplierListForRewards,
} = supplierSlice.actions

export default supplierSlice.reducer
