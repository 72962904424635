import { captureException } from '@sentry/nextjs'

import { endpointKeys } from '../endpoints'
import { ERRORS, LocoError } from '../errors'
import LeadService from './leadService'
import RestService from './restService'

/**
 *  Adds price alert
 *
 * @param {Object} params - Parameters passed to {@link RestService.post}.
 * @param {VehicleId} params.vehicleId - ID of vehicle that belongs to price alert.
 * @param {CognitoIdToken.getJwtToken} params.token - Cognito Authentication Token
 * @returns {Promise<vehicleId: number>} - Promise that resolves with the response.
 * @throws {LocoError} - User friendly error message.
 */
export const addPriceAlertService = async params => {
  try {
    return await RestService.post(endpointKeys.addPriceAlert, { ...params })
  } catch (error) {
    captureException(error)
    throw {
      message: "We're unable to add this price alert right now. Please try again later.",
      code: ERRORS.addingPriceAlert,
      error,
    }
  }
}

/**
 *  Removes price alert
 *
 * @param {Object} params - Parameters passed to {@link RestService.post}.
 * @param {VehicleId} params.vehicleId - ID of vehicle that belongs to price alert.
 * @param {CognitoIdToken.getJwtToken} params.token - Cognito Authentication Token
 * @returns {Promise<null>} - Promise that resolves with the response.
 * @throws {LocoError} - User friendly error message.
 */
export const removePriceAlertService = async params => {
  try {
    return await RestService.post(endpointKeys.removePriceAlert, { ...params })
  } catch (error) {
    captureException(error)
    throw {
      message: "We're unable to remove this price alert at this time. Please try again later.",
      code: ERRORS.removingPriceAlert,
      error,
    }
  }
}
/**
 * Sends an enquiry containing deal ID and phone number
 *
 * @param {Object} params - Parameters passed to {@link RestService.post}.
 * @param {CognitoIdToken.getJwtToken} params.token - Cognito Authentication Token
 * @param {string} params.deal_id - ID of Deal.
 * @param {string} params.phone_number - Enquirer's phone number.
 * @returns {Promise<*>}
 * @throws {LocoError}
 */
export const sendEnquiryService = async params => {
  try {
    return RestService.post(endpointKeys.saveEnquiry, { ...params })
  } catch (error) {
    captureException(error)
    throw {
      message: "We're unable to send the enquiry. Please try again later.",
      code: ERRORS.sendingEnquiry,
      error,
    }
  }
}

/**
 * Gets User details
 *
 * @param {Object} params - Parameters passed to {@link RestService.get}.
 * @param {CognitoIdToken.getJwtToken} params.token - Cognito Authentication Token
 * @returns {Promise<*>} -
 * @throws {LocoError}
 */
export const getUserDetailsService = async params => {
  try {
    return await RestService.get(endpointKeys.userDetails, { ...params })
  } catch (error) {
    captureException(error)
    throw {
      message: 'We are unable to retrieve your account details. Please try again later.',
      code: ERRORS.fetchingAccountInfo,
      error,
    }
  }
}

/**
 * Saves User details
 *
 * @param {Object} params - Parameters passed to {@link RestService.post}.
 * @param {CognitoIdToken.getJwtToken} params.token - Cognito Authentication Token
 * @returns {Promise<*>} -
 * @throws {LocoError}
 */
export const saveUserDetailsService = async params => {
  try {
    return await RestService.post(endpointKeys.saveUserDetails, { ...params })
  } catch (error) {
    captureException(error)
    throw {
      message: 'We are unable to save your account details. Please try again later.',
      code: ERRORS.savingAccountInfo,
      error,
    }
  }
}

export const getUserPriceAlertPreferencesService = async params => {
  return await RestService.get(endpointKeys.userPriceAlertPreferences, { ...params })
}

export const setUserPriceAlertPreferencesService = async params => {
  return await RestService.post(endpointKeys.userPriceAlertPreferences, { ...params })
}

export const getUserMarketingEmailPreferencesService = async params => {
  return await RestService.get(endpointKeys.userMarketingEmailPreferences, { ...params })
}

export const setUserMarketingEmailPreferencesService = async params => {
  return await RestService.post(endpointKeys.userMarketingEmailPreferences, { ...params })
}

export const getUserMarketingSmsPreferencesService = async params => {
  return await RestService.get(endpointKeys.userMarketingSmsPreferences, { ...params })
}

export const setUserMarketingSmsPreferencesService = async params => {
  return await RestService.post(endpointKeys.userMarketingSmsPreferences, { ...params })
}

export const getUsernameService = async params => {
  return await RestService.get(endpointKeys.userGetUserName, { ...params })
}

export const sendFeedbackService = async params => {
  return await RestService.post(endpointKeys.sendFeedback, { ...params })
}

export const saveRewardDetailsService = async params => {
  return await RestService.post(endpointKeys.saveRewardDetails, { ...params })
}

export const getRewardsHistoryService = async params => {
  return await RestService.get(endpointKeys.rewardsHistory, { ...params })
}

export const removeSavedSearchService = async params => {
  return await RestService.post(endpointKeys.removeSavedSearch, { ...params })
}

export const getExperimentsService = async params => {
  return await RestService.get(endpointKeys.experimentList, { ...params })
}

export const verifyEnquiryService = async params => {
  return await RestService.post(endpointKeys.verifyEnquiry, { ...params })
}

/**
 * Gets list of active price alerts
 *
 * @param {Object} params - Parameters passed to {@link RestService.get}.
 * @param {CognitoIdToken.getJwtToken} params.token - Cognito Authentication Token
 * @param {number} params.pageNumber
 * @param {number} params.pageSize
 * @returns {Promise<*>} - Contains array of active price alert objects
 * @throws {LocoError}
 */
export const getActivePriceAlertsService = async params => {
  try {
    return await RestService.get(endpointKeys.activePriceAlerts, { ...params })
  } catch (error) {
    throw new LocoError({
      message: 'We were unable to get your active price alert list. Please try again later.',
      code: ERRORS.fetchingActivePriceAlertsList,
      error,
      params,
    })
  }
}

/**
 * Checks if price alert exists
 *
 * @param {Object} params - Parameters passed to {@link RestService.get}.
 * @param {CognitoIdToken.getJwtToken} params.token - Cognito Authentication Token
 * @param {VehicleId} params.vehicleId - Cognito Authentication Token
 * @param {number} params.initialPaymentInMonths
 * @param {number} params.leaseType
 * @param {number} params.term
 * @param {number} params.stockStatus
 * @param {number} params.mileage
 * @returns {Promise<*>} - Contains vehicle ID if price alert exists
 * @throws {LocoError}
 */
export const checkPriceAlertExistsService = async params => {
  try {
    return await RestService.get(endpointKeys.checkPriceAlertExists, { ...params })
  } catch (error) {
    if (error.status === 404) {
      // price alert for vehicle does not exist
      return false
    } else {
      throw new LocoError({
        message: 'We were unable to check if a price alert exists. Please try again later.',
        code: ERRORS.checkingPriceAlertExists,
        error,
        params,
      })
    }
  }
}

export const sendLeadDetailsService = async params => {
  return await RestService.post(endpointKeys.sendLeadDetails, { ...params })
}

export const sendLeadVerificationService = async params => {
  return await RestService.post(endpointKeys.sendLeadVerification, { ...params })
}

export const requestLeadVerificationCodeService = async params => {
  return await RestService.post(endpointKeys.requestLeadVerificationCode, { ...params })
}

export const sendPcpLeadDetailsService = async params => {
  return await LeadService.post(endpointKeys.sendPcpLeadDetails, { ...params })
}

export const sendPoorCreditLeadDetailsService = async params => {
  return await LeadService.post(endpointKeys.sendPoorCreditLeadDetails, { ...params })
}

export const sendAlternativeLeadVerificationService = async params => {
  return await LeadService.post(endpointKeys.sendAlternativeLeadVerification, {
    ...params,
  })
}

export const requestAlternativeLeadVerificationCodeService = async params => {
  return await LeadService.post(endpointKeys.requestLeadVerificationCode, { ...params })
}

export const sendNewsletterDetailsService = async params => {
  return await RestService.post(endpointKeys.newsletterSignup, { ...params })
}

export const sendSalarySacrificeEnquiryService = async params => {
  return await RestService.post(endpointKeys.saveSalarySacrificeEnquiry, { ...params })
}
