import { fetchAuthSession } from 'aws-amplify/auth'
import dayjs from 'dayjs'
import advancedFormat from 'dayjs/plugin/advancedFormat'
import utc from 'dayjs/plugin/utc'

import { constants } from '../constants'

dayjs.extend(advancedFormat)
dayjs.extend(utc)

export const checkForUser = () =>
  fetchAuthSession()
    .then(data => data.tokens?.idToken?.toString())
    .catch(err => {
      if (process.env.NEXT_PUBLIC_ENVIRONMENT !== constants.environments.PRODUCTION) {
        console.log(err)
      }
    })

export const formatRewardDate = date => dayjs(date).format(constants.rewardDateFormat)

export const getRewardType = type => {
  switch (type) {
    case 10:
      return 'Refer a Friend'
    case 20:
      return 'Reward'
    default:
      return ''
  }
}

export const getRewardStatus = status => {
  switch (status) {
    case 10:
      return 'Pending Approval'
    case 20:
      return 'Approved'
    case 30:
      return 'Declined'
    case 40:
      return 'Voucher Sent'
    default:
      return ''
  }
}

export const daysSinceDealSaved = savedDate => {
  const currentDate = dayjs()
  const difference = currentDate.diff(dayjs(savedDate), 'days')

  if (difference === 1) {
    return '1 Day'
  }

  return `${difference} Days`
}

const removeExcessZeros = (number, prefix) => {
  if (number.startsWith(prefix + '0')) {
    return `${prefix}${number.slice(prefix.length + 1)}`
  }
  return number
}

export const parsePhoneNumber = (number, shouldHavePrefix) => {
  if (!number) {
    return number
  }

  // removes spaces
  number = number.replace(/\s/g, '')

  if (number.startsWith('+44')) {
    number = shouldHavePrefix ? removeExcessZeros(number, '+44') : number.slice(3)
  } else if (number.startsWith('44')) {
    number = shouldHavePrefix ? '+' + removeExcessZeros(number, '44') : number.slice(2)
  } else if (number.startsWith('0')) {
    number = shouldHavePrefix ? '+44' + number.slice(1) : number
  } else if (shouldHavePrefix) {
    number = '+44' + number
  }

  return number
}

export const hasAccountBeenCreatedRecently = dateCreated => {
  const FIVE_MINUTES = 300000,
    now = dayjs().utc().valueOf(),
    createdDate = dayjs(dateCreated).utc().valueOf(),
    difference = now - createdDate

  return difference < FIVE_MINUTES
}

const account = {
  checkForUser,
  formatRewardDate,
  getRewardType,
  getRewardStatus,
  daysSinceDealSaved,
  parsePhoneNumber,
  hasAccountBeenCreatedRecently,
}

export default account
