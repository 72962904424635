import { combineEpics, ofType } from 'redux-observable'
import { concat, from, of } from 'rxjs'
import { catchError, map, switchMap } from 'rxjs/operators'

import { ERRORS } from '../../lib/errors'
import { LOADERS } from '../../lib/loaders'
import { getListOfAllSuppliersService } from '../../lib/services/supplierService'
import { getRewardsHistoryService, saveRewardDetailsService } from '../../lib/services/userService'
import { checkForUser } from '../../lib/utilities/account'
import { addError, removeError } from '../reducers/error'
import { addLoader, removeLoader } from '../reducers/loader'
import { getSupplierListForRewards, storeSupplierListForRewards } from '../reducers/supplier'
import {
  getRewardsHistory,
  saveDealDetailsForRewards,
  storeIsRewardSubmissionSuccessful,
  storeRewardsHistory,
} from '../reducers/user'

export const getRewardsSupplierListEffect = action$ => {
  const LOADER = LOADERS.fetchingSupplierList
  const ERROR = ERRORS.fetchingSupplierList

  return action$.pipe(
    ofType(getSupplierListForRewards),
    switchMap(() => {
      return concat(
        of(addLoader(LOADER)),
        of(removeError(ERROR)),
        of(storeIsRewardSubmissionSuccessful(false)),
        from(getListOfAllSuppliersService()).pipe(
          switchMap(response => {
            return concat(of(storeSupplierListForRewards(response)), of(removeLoader(LOADER)))
          }),
          catchError(err => {
            return concat(
              of(removeLoader(LOADER)),
              of(
                addError({
                  key: ERROR,
                  message:
                    err.message ||
                    'There has been an issue fetching the list of brokers. Please try again later.',
                }),
              ),
            )
          }),
        ),
      )
    }),
  )
}

export const verifyRewardEffect = action$ => {
  const LOADER = LOADERS.savingDealForRewards
  const API_ERROR = ERRORS.savingDealForRewards
  const USER_ERROR = ERRORS.checkForUser

  return action$.pipe(
    ofType(saveDealDetailsForRewards),
    map(action => action.payload),
    switchMap(params => {
      return concat(
        of(addLoader(LOADER)),
        of(removeError(API_ERROR)),
        of(removeError(USER_ERROR)),
        from(checkForUser()).pipe(
          switchMap(token => {
            return from(
              saveRewardDetailsService({
                brokerId: params.brokerId,
                orderId: params.orderId,
                promoCode: params.promoCode,
                phoneNumber: params.phoneNumber,
                token,
              }),
            ).pipe(
              switchMap(() => {
                return concat(
                  of(removeLoader(LOADER)),
                  of(storeIsRewardSubmissionSuccessful(true)),
                  of(getRewardsHistory()),
                )
              }),
              catchError(err => {
                return concat(
                  of(removeLoader(LOADER)),
                  of(
                    addError({
                      key: API_ERROR,
                      message:
                        err.message ||
                        'There has been an issue saving your details. Please try again later.',
                    }),
                  ),
                )
              }),
            )
          }),
          catchError(err => {
            return concat(
              of(removeLoader(LOADER)),
              of(
                addError({
                  key: USER_ERROR,
                  message:
                    err.message ||
                    'There has been an issue fetching your user credentials. Please try again later.',
                }),
              ),
            )
          }),
        ),
      )
    }),
  )
}

export const rewardsHistoryEffect = action$ => {
  const LOADER = LOADERS.fetchingRewardsHistory
  const API_ERROR = ERRORS.fetchingRewardsHistory
  const USER_ERROR = ERRORS.checkForUser

  return action$.pipe(
    ofType(getRewardsHistory),
    switchMap(() => {
      return concat(
        of(addLoader(LOADER)),
        of(removeError(API_ERROR)),
        of(removeError(USER_ERROR)),
        from(checkForUser()).pipe(
          switchMap(token => {
            return from(getRewardsHistoryService({ token })).pipe(
              switchMap(response => {
                return concat(of(storeRewardsHistory(response)), of(removeLoader(LOADER)))
              }),
              catchError(err => {
                return concat(
                  of(removeLoader(LOADER)),
                  of(
                    addError({
                      key: API_ERROR,
                      message:
                        err.message ||
                        'There has been an issue saving your details. Please try again later.',
                    }),
                  ),
                )
              }),
            )
          }),
          catchError(err => {
            return concat(
              of(removeLoader(LOADER)),
              of(
                addError({
                  key: USER_ERROR,
                  message:
                    err.message ||
                    'There has been an issue fetching your user credentials. Please try again later.',
                }),
              ),
            )
          }),
        ),
      )
    }),
  )
}

export const rewardsPageEffect = combineEpics(
  getRewardsSupplierListEffect,
  verifyRewardEffect,
  rewardsHistoryEffect,
)
