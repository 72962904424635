import { combineEpics, ofType } from 'redux-observable'
import { from } from 'rxjs'
import { map, switchMap, filter } from 'rxjs/operators'

import { getRangesForManufacturersService } from '../../lib/services/searchService'
import {
  getRangesForManufacturers,
  storeVehicleRanges,
  removeSearchParameters,
  storeSearchParameters,
  toggleInStock,
} from '../reducers/search'
import { FILTER_IN_STOCK } from '../../lib/constants'

export const getRangesForManufacturersEffect = action$ => action$.pipe(
    ofType(getRangesForManufacturers),
    map(action => action.payload),
    switchMap(params => from(getRangesForManufacturersService(params)).pipe(
        map(response => storeVehicleRanges(response)),
      ),
    ),
  )

export const getRangesInStockEffect = action$ => action$.pipe(
    ofType(toggleInStock),
    map(action => action.payload),
    filter(payload => !!payload),
    map(() => storeSearchParameters({ filter: FILTER_IN_STOCK, value: 1 })),
  )

export const removeRangesInStockEffect = action$ => action$.pipe(
    ofType(toggleInStock),
    map(action => action.payload),
    filter(payload => !payload),
    map(() => removeSearchParameters({ filter: FILTER_IN_STOCK })),
  )


export const rangesEffect = combineEpics(
  getRangesForManufacturersEffect,
  getRangesInStockEffect,
  removeRangesInStockEffect,
)
