import { createSlice } from '@reduxjs/toolkit'

export const INITIAL_STATE = {
  openFilterDepth: 1,
  selectedAlloys: 0,
  selectedParkingSensors: 0,
  selectedHeatedSeats: 0,
  selectedCo2: 0,
  selectedMpg: 0,
  selectedZeroToSixtyTwo: 0,
  selectedEngineMin: 0,
  selectedEngineMax: 0,
  filtersHaveChanged: false,
}

const filterSlice = createSlice({
  name: 'filter',
  initialState: INITIAL_STATE,
  reducers: {
    storeOpenFilterDepth: (state, action) => {
      state.openFilterDepth = action.payload
    },
    storeSelectedAlloys: (state, action) => {
      state.selectedAlloys = action.payload
    },
    storeSelectedParkingSensors: (state, action) => {
      state.selectedParkingSensors = action.payload
    },
    storeSelectedHeatedSeats: (state, action) => {
      state.selectedHeatedSeats = action.payload
    },
    storeCo2: (state, action) => {
      state.selectedCo2 = action.payload
    },
    storeMpg: (state, action) => {
      state.selectedMpg = action.payload
    },
    storeZeroToSixtyTwo: (state, action) => {
      state.selectedZeroToSixtyTwo = action.payload
    },
    storeEngineMin: (state, action) => {
      state.selectedEngineMin = action.payload
    },
    storeEngineMax: (state, action) => {
      state.selectedEngineMax = action.payload
    },
    storeFiltersHaveChanged: (state, action) => {
      state.filtersHaveChanged = action.payload
    },
    clearSelectedEngineFilters: state => {
      state.selectedCo2 = INITIAL_STATE.selectedCo2
      state.selectedMpg = INITIAL_STATE.selectedMpg
      state.selectedZeroToSixtyTwo = INITIAL_STATE.selectedZeroToSixtyTwo
      state.selectedEngineMin = INITIAL_STATE.selectedEngineMin
      state.selectedEngineMax = INITIAL_STATE.selectedEngineMax
    },
    clearSelectedSpecFilters: state => {
      state.selectedAlloys = INITIAL_STATE.selectedAlloys
      state.selectedParkingSensors = INITIAL_STATE.selectedParkingSensors
      state.selectedHeatedSeats = INITIAL_STATE.selectedHeatedSeats
    },
    clearAllFilterState: state => {
      state.openFilterDepth = INITIAL_STATE.openFilterDepth
      state.selectedCo2 = INITIAL_STATE.selectedCo2
      state.selectedMpg = INITIAL_STATE.selectedMpg
      state.selectedZeroToSixtyTwo = INITIAL_STATE.selectedZeroToSixtyTwo
      state.selectedEngineMin = INITIAL_STATE.selectedEngineMin
      state.selectedEngineMax = INITIAL_STATE.selectedEngineMax
      state.selectedAlloys = INITIAL_STATE.selectedAlloys
      state.selectedParkingSensors = INITIAL_STATE.selectedParkingSensors
      state.selectedHeatedSeats = INITIAL_STATE.selectedHeatedSeats
    },
    resetFilterState: state => {
      state.selectedCo2 = INITIAL_STATE.selectedCo2
      state.selectedMpg = INITIAL_STATE.selectedMpg
      state.selectedZeroToSixtyTwo = INITIAL_STATE.selectedZeroToSixtyTwo
      state.selectedEngineMin = INITIAL_STATE.selectedEngineMin
      state.selectedEngineMax = INITIAL_STATE.selectedEngineMax
      state.selectedAlloys = INITIAL_STATE.selectedAlloys
      state.selectedParkingSensors = INITIAL_STATE.selectedParkingSensors
      state.selectedHeatedSeats = INITIAL_STATE.selectedHeatedSeats
    },
  },
})

export const {
  storeOpenFilterDepth,
  storeSelectedAlloys,
  storeSelectedParkingSensors,
  storeSelectedHeatedSeats,
  storeCo2,
  storeMpg,
  storeZeroToSixtyTwo,
  storeEngineMin,
  storeEngineMax,
  storeFiltersHaveChanged,
  clearSelectedEngineFilters,
  clearSelectedSpecFilters,
  clearAllFilterState,
  resetFilterState,
} = filterSlice.actions

export default filterSlice.reducer
