import { endpointKeys } from '../endpoints'
import RestService from './restService'

export const getVehicleService = async params => {
  return await RestService.get(endpointKeys.vehicle, { ...params })
}

export const getVehicleReviewService = async params => {
  return await RestService.get(endpointKeys.vehicleReview, { ...params })
}

export const getHistoricalPricesFromLeaseProfileService = async params => {
  return await RestService.get(endpointKeys.historicalPricesFromLeaseProfile, {
    ...params,
  })
}

export const getBestDealsByBodyStyleService = async params => {
  return await RestService.get(endpointKeys.bestDealsByBodyStyle, { ...params })
}

export const getAvailableLeaseProfileFiltersService = async params => {
  return await RestService.get(endpointKeys.availableLeaseProfiles, { ...params })
}

// Used on the vehicle configurator page
export const getVehicleTechnicalDataService = async params => {
  return await RestService.get(endpointKeys.vehicleTechnicalData, { ...params })
}

export const getVehicleStandardOptionsService = async params => {
  return await RestService.get(endpointKeys.vehicleStandardOptions, { ...params })
}

export const getConfiguratorResultsService = async params => {
  return await RestService.get(endpointKeys.configuratorSearchRequest, { ...params })
}

export const getConfiguratorModelAnalyticsService = async params => {
  return await RestService.get(endpointKeys.configuratorModelViews, { ...params })
}

export const getVehiclePaintService = async params => {
  return await RestService.get(endpointKeys.vehiclePaint, { ...params })
}
