import { combineEpics, ofType } from 'redux-observable'
import { concat, iif, of } from 'rxjs'
import { filter, map, switchMap } from 'rxjs/operators'

import {
  FILTER_LEASE_TYPES,
  FILTER_LEASE_TYPES_PERSONAL,
  FILTER_MANUFACTURERS,
  FILTER_MILEAGES,
  FILTER_MONTHLY_PAYMENT_MAX,
  FILTER_VEHICLE_TYPES,
  FILTER_VEHICLE_TYPES_VANS,
  FILTER_WHEELBASE,
} from '../../lib/constants'
import { removeVatFromPrice } from '../../lib/utilities/search'
import { clearSelectedEngineFilters, clearSelectedSpecFilters } from '../reducers/filter'
import {
  clearSearchParameters,
  clearTextSearch,
  findADeal,
  findManufacturers,
  storeHomepageSearchParameters,
  storeLeaseType,
  storeSavedSearchReference,
  toggleVat,
} from '../reducers/search'

export const findADealEffect = action$ =>
  action$.pipe(
    ofType(findADeal),
    map(action => action.payload),
    filter(payload => payload),
    switchMap(params =>
      concat(
        of(clearSearchParameters()),
        of(clearTextSearch()),
        of(clearSelectedEngineFilters()),
        of(clearSelectedSpecFilters()),
        of(storeSavedSearchReference('')),
        of(
          storeHomepageSearchParameters({
            filter: FILTER_VEHICLE_TYPES,
            value: [Number(params.vehicleTypes)],
          }),
        ),
        iif(
          () => params.monthlyPaymentMax !== 9999998,
          of(
            storeHomepageSearchParameters({
              filter: FILTER_MONTHLY_PAYMENT_MAX,
              value:
                Number(params.leaseTypes) === FILTER_LEASE_TYPES_PERSONAL
                  ? removeVatFromPrice(Number(params.monthlyPaymentMax))
                  : Number(params.monthlyPaymentMax),
            }),
          ),
        ),
        of(
          storeHomepageSearchParameters({
            filter: FILTER_LEASE_TYPES,
            value: [Number(params.leaseTypes)],
          }),
        ),
        iif(
          () => params.vehicleTypes === FILTER_VEHICLE_TYPES_VANS && params.wheelbase > 0,
          of(
            storeHomepageSearchParameters({
              filter: FILTER_WHEELBASE,
              value: [Number(params.wheelbase)],
            }),
          ),
        ),
        of(storeLeaseType(Number(params.leaseTypes))),
        of(toggleVat(Number(params.leaseTypes) === FILTER_LEASE_TYPES_PERSONAL)),
      ),
    ),
  )

export const findManufacturersEffect = action$ =>
  action$.pipe(
    ofType(findManufacturers),
    map(action => action.payload),
    filter(payload => payload),
    switchMap(params =>
      concat(
        of(clearSearchParameters()),
        of(clearTextSearch()),
        of(clearSelectedEngineFilters()),
        of(clearSelectedSpecFilters()),
        of(storeSavedSearchReference('')),
        of(
          storeHomepageSearchParameters({
            filter: FILTER_VEHICLE_TYPES,
            value: [Number(params.vehicleTypes)],
          }),
        ),
        of(
          storeHomepageSearchParameters({
            filter: FILTER_MONTHLY_PAYMENT_MAX,
            value: 9999998,
          }),
        ),
        of(
          storeHomepageSearchParameters({
            filter: FILTER_MILEAGES,
            value: [],
          }),
        ),
        of(
          storeHomepageSearchParameters({
            filter: FILTER_LEASE_TYPES,
            value: [Number(params.leaseTypes)],
          }),
        ),
        of(
          storeHomepageSearchParameters({
            filter: FILTER_MANUFACTURERS,
            value: [...params.manufacturers],
          }),
        ),
        of(storeLeaseType(Number(params.leaseTypes))),
        of(toggleVat(Number(params.leaseTypes) === FILTER_LEASE_TYPES_PERSONAL)),
      ),
    ),
  )

export const homepageSearchEffect = combineEpics(findADealEffect, findManufacturersEffect)
