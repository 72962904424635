import { combineEpics, ofType } from 'redux-observable'
import { ignoreElements, map, tap } from 'rxjs/operators'
import Cookies from 'js-cookie'

import { constants } from '@/lib/constants'
import { postAnalyticsEventService } from '@/lib/services/eventService'
import { addToDataLayer } from '@/lib/utilities/vehicle'
import { getBrowser, getDevice, getOS, createUnixTimestampForCurrentTime } from '@/lib/utilities/system'
import { POST_ANALYTICS_EVENT, SEND_TO_DATA_LAYER } from '../actions/event'
import { selectQueryString } from '../selectors/system'
import {
  selectExperiment,
  selectFirstTouchpoint,
  selectLastTouchpoint,
  selectUsersDotDigitalId,
  selectUsersUsername,
  selectUsersVisitorKey,
  selectUsersVisitorKeyTimestamp,
} from '../selectors/user'

export const postAnalyticsEventEffect = (action$, state$) =>
  action$.pipe(
    ofType(POST_ANALYTICS_EVENT),
    map(action => action.payload),
    tap(data => {
      const str = new URLSearchParams(selectQueryString(state$.value))
      let queryStringParams = {}

      for (const param of str.entries()) {
        if (param[1]) {
          queryStringParams[param[0]] = param[1]
        }
      }

      postAnalyticsEventService({
        timestamp: createUnixTimestampForCurrentTime(),
        visitorKey: selectUsersVisitorKey(state$.value),
        visitorKeyTimestamp: selectUsersVisitorKeyTimestamp(state$.value),
        userId: selectUsersUsername(state$.value),
        location: {
          path: data.path,
          params: queryStringParams,
        },
        app: {
          source: constants.appName,
          version: constants.version,
          environment: process.env.NEXT_PUBLIC_ENVIRONMENT,
        },
        event: data.event,
        payload: data.payload,
        firstTouch: selectFirstTouchpoint(state$.value),
        lastTouch: selectLastTouchpoint(state$.value),
        experiment: {
          experimentName: [selectExperiment(state$.value).name],
          experimentGroup: [selectExperiment(state$.value).group],
        },
        device: {
          type: getDevice().type || 'desktop',
          browser: getBrowser().name,
          os: getOS().name,
        },
        miscellaneous: {
          // eslint-disable-next-line camelcase
          llit_dd_uid: selectUsersDotDigitalId(state$.value),
          fbc: Cookies.get('_fbc'),
          fbp: Cookies.get('_fbp'),
        },
      })
    }),
    ignoreElements(),
  )

const sendToDataLayerEffect = action$ =>
  action$.pipe(
    ofType(SEND_TO_DATA_LAYER),
    map(action => action.payload),
    tap(payload => addToDataLayer(payload)),
    ignoreElements(),
  )

export const eventsEffect = combineEpics(postAnalyticsEventEffect, sendToDataLayerEffect)
