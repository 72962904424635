import { createSlice } from '@reduxjs/toolkit'

import { constants } from '../../lib/constants'

export const INITIAL_STATE = {
  version: constants.version,
  queryString: null,
  isFirstRender: true,
  showBenefitsModal: false,
  hasCookiebotLoaded: false,
}

const systemSlice = createSlice({
  name: 'system',
  initialState: INITIAL_STATE,
  reducers: {
    storeVersion: (state, action) => {
      state.version = action.payload
    },
    storeQueryString: (state, action) => {
      state.queryString = action.payload
    },
    storeIsFirstRender: (state, action) => {
      state.isFirstRender = action.payload
    },
    storeShowBenefitsModal: (state, action) => {
      state.showBenefitsModal = action.payload
    },
    storeHasCookieBotLoaded: (state, action) => {
      state.hasCookieBotLoaded = action.payload
    },
  },
})

export const {
  storeVersion,
  storeIsFirstRender,
  storeQueryString,
  storeShowBenefitsModal,
  storeHasCookieBotLoaded,
} =
  systemSlice.actions

export default systemSlice.reducer
