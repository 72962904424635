/* eslint-disable camelcase */
const awsmobile = {
  aws_project_region: 'eu-west-1',
  aws_cognito_identity_pool_id: process.env.NEXT_PUBLIC_AWS_AUTH_IDENTITY_POOL_ID,
  aws_cognito_region: process.env.NEXT_PUBLIC_AWS_AUTH_REGION,
  aws_user_pools_id: process.env.NEXT_PUBLIC_AWS_AUTH_USER_POOL_ID,
  aws_user_pools_web_client_id: process.env.NEXT_PUBLIC_AWS_AUTH_USER_POOL_WEB_CLIENT_ID,
  oauth: {
    domain: 'auth.leaseloco.com',
    scope: ['email', 'openid', 'profile', 'aws.cognito.signin.user.admin'],
    redirectSignIn: `${process.env.NEXT_PUBLIC_URL}/auth`,
    redirectSignOut: `${process.env.NEXT_PUBLIC_URL}/logout`,
    responseType: 'code',
  },
  federationTarget: process.env.NEXT_PUBLIC_AWS_AUTH_FEDERATION_TARGET,
  Auth: {
    region: process.env.NEXT_PUBLIC_AWS_AUTH_REGION,
    identityPoolId: process.env.NEXT_PUBLIC_AWS_AUTH_IDENTITY_POOL_ID,
    userPoolId: process.env.NEXT_PUBLIC_AWS_AUTH_USER_POOL_ID,
    userPoolWebClientId: process.env.NEXT_PUBLIC_AWS_AUTH_USER_POOL_WEB_CLIENT_ID,
    federationTarget: process.env.NEXT_PUBLIC_AWS_AUTH_FEDERATION_TARGET,
    oauth: {
      domain: 'auth.leaseloco.com',
      scope: ['email', 'openid', 'profile', 'aws.cognito.signin.user.admin'],
      redirectSignIn: `${process.env.NEXT_PUBLIC_URL}/auth`,
      redirectSignOut: `${process.env.NEXT_PUBLIC_URL}/logout`,
      responseType: 'code',
    },
  },
  Storage: {
    AWSS3: {
      bucket: process.env.NEXT_PUBLIC_AWS_STORAGE_BUCKET,
      region: process.env.NEXT_PUBLIC_AWS_STORAGE_REGION,
    },
  },
}

export default awsmobile
