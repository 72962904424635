import NiceModal from '@ebay/nice-modal-react'
import { Amplify } from 'aws-amplify'
import { useEffect, useState } from 'react'
import { Provider } from 'react-redux'

import Layout from '../components/views/Layout'
import config from '../lib/aws-exports'
import { constants } from '../lib/constants'
import store from '../store'
import '../styles/index.scss'

Amplify.configure({ ...config })

function MyApp({ Component, pageProps }) {
  const [blocker, setBlocker] = useState(false)

  useEffect(() => {
    const isIncorrectDomain =
      process.env.NEXT_PUBLIC_URL !== window.location.origin &&
      process.env.NEXT_PUBLIC_ENVIRONMENT === constants.environments.PRODUCTION

    if (process.env.NEXT_PUBLIC_ENVIRONMENT !== constants.environments.PRODUCTION) {
      // eslint-disable-next-line no-console
      console.log('PUBLIC URL: ', process.env.NEXT_PUBLIC_URL)
      // eslint-disable-next-line no-console
      console.log('window origin: ', window.location.origin)
      // eslint-disable-next-line no-console
      console.log('Env: ', process.env.NEXT_PUBLIC_ENVIRONMENT)
    }
    setBlocker(isIncorrectDomain)
  }, [])

  if (blocker) {
    return (
      <div>
        <p>Unable to access application</p>
      </div>
    )
  }

  return (
    <Provider store={store}>
      <NiceModal.Provider>
        <Layout>
          <Component {...pageProps} />
        </Layout>
      </NiceModal.Provider>
    </Provider>
  )
}

export default MyApp
